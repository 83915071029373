import { InputMessage } from "src/models/inputMessage"



export const validator =(array:Array<Function>, value:string):InputMessage[] =>{
    let arrayMessage:InputMessage[]=[]
    for (let i = 0; i < array.length; i++) {
        const validator = array[i];
        const result=validator(value)
        if(result){
            arrayMessage.push({message:result})
        }
      }

    return arrayMessage
}


export const nameText:Array<Function> = [
    (v:string) => !!v || 'Name is required',
    (v:string) => (v && v.length >= 3) || 'Name must be more than 3 characters',

]

export const addressText:Array<Function> = [
    (v:string) => !!v || 'Address is required',
    (v:string) => (v && v.length >= 3) || 'Name must be more than 3 characters',

]

export const cityText:Array<Function> = [
    (v:string) => !!v || 'City is required',
    (v:string) => (v && v.length >= 3) || 'Name must be more than 3 characters',

]

export const stateText:Array<Function> = [
    (v:string) => !!v || 'State is required',
    (v:string) => (v && v.length >= 3) || 'Name must be more than 3 characters',

]

export const zipText:Array<Function> = [
    (v:string) => !!v || 'Zip is required',
    (v:string) => (v && v.length >= 3) || 'Name must be more than 3 characters',
    (v:string) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) || 'Zip must be valid',
]

export const cvvText:Array<Function> = [
    (v:string) => !!v || 'cvv is required',
    (v:string) => (v && v.length === 3) || 'cvv must be 3 characters',
    (v:string) => /^\d+$/.test(v) || 'cvv must be valid',


]

export const cardNumberText: Array<Function> = [
    (v: string) => !!v || 'Card number is required',
    (v: string) => /^\d{16}$/.test(v.replace(/\s/g, '')) || 'Card number must be exactly 16 digits',

];

export const dateText: Array<Function> = [
    (v: string) => !!v || 'Expiration date is required',
    (v: string) => {
        const [month, year] = v.split('/');
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear() % 100; // Get last two digits of current year
        const currentMonth = currentDate.getMonth() + 1; // Month is zero-based
        const inputYear = parseInt(year, 10);
        const inputMonth = parseInt(month, 10);
        return (
            inputYear >= currentYear && // Year should be current year or later
            inputYear <= currentYear + 20 && // Assume card expiry can't be more than 20 years in future
            inputMonth >= 1 && inputMonth <= 12 && // Month should be between 1 and 12
            (inputYear !== currentYear || inputMonth >= currentMonth) // Expiry should be in future months of current year
        ) || 'Invalid expiration date';
    },
];



