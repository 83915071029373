import React from 'react'

//styles
import './ChargerCard.css'


//models
import { Charger } from 'src/models/charger'

interface Props{
    charger: Charger,

}

const ChargerCard = ({charger}:Props) => {
  return (
    <div className='ChargerCard-Component'>
        
        <div className='ChargerCard-Date'>
            <span>From 7 AM</span>
        </div>


        <img alt='charger' src={charger.img}></img>
        <span>1.5 CAD / hr</span>
        <span>{charger.name}</span>

        <div className={'ChargerCard-SubComponent ' + (charger.available ?"ChargerCard-SubComponent-Available" : "ChargerCard-SubComponent-Disabled")}>
            <span>Available</span>
        </div>
    </div>
  )
}

export default ChargerCard