import React from 'react';

import './ThirdButton.css';


interface Props{
    text: string;
    status: "STARTED" | "STOPPED";
}

const ThirdButton = ({text, status}:Props) => {
    return(
        <button className='ThirdButton'>
        {status === "STARTED" ? "In use" : "Avaliable"}
    </button>
    )
}

export default ThirdButton;