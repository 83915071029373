import React, { useContext, useEffect, useRef, useState } from 'react';
import './Home.css';
import TitleText from 'src/components/TitleText/TitleText';
import SubText from 'src/components/SubText/SubText';
import Notification from 'src/icons/Notification';
import SubTitle from 'src/components/SubTitle/SubTitle';
import CardHome from 'src/components/CardHome/CardHome';
import Slider from 'src/components/Slider/Slider';
import { PromisePageContext } from 'src/machines/pageMachine';
import { useNavigate, useLocation } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { chargerId } = location.state || {};
  let chargerSerialId = chargerId; //available directly from chargerId

  const cardsContainerRef = useRef<HTMLDivElement | null>(null);
  //const [isElementInCenter, setIsElementInCenter] = useState(false);
    const { page, pageSend } = useContext(PromisePageContext);
    const [active,setActive] = useState<boolean>(false);
    const [selectedElement, setSelectedElement] = useState<number>(0);
    
    const [charger] = useState<any[]>([
      { 
        id: 1, 
        name: "My Charger", 
        type: "1.5 CAD / hr", 
        status: "STARTED", 
        image: chargerId === 'teltonika' ? "/image/teltonika.jpg" : 
               chargerId === 'fractal' ? "/image/fractal2.png" :
               chargerId === 'evduty' ? "/image/evduty.png" :
               "/image/teltonika.jpg"  // fallback image if none of the above matches
      },
    ]);
    

    let name = 'EV Driver'


    const styles = {
      backgroundColor: page.context.darkMode ? "#141D2F" : "#FFFFFF",
      color: page.context.darkMode ? "#FFFFFF" : "#000000",
    }

    useEffect(() => {

      }, [selectedElement]);

       useEffect(() => {
        const handleScroll = () => {
          if (cardsContainerRef.current) {

            const container = cardsContainerRef.current;
            const containerWidth = container.offsetWidth;
            const scrollLeft = container.scrollLeft;
            const centerPosition = containerWidth / 2;
            const cards = container.getElementsByClassName('CardHome-container') as HTMLCollectionOf<HTMLElement>;
            let elementInCenterIndex = null;

            for (let index = 0; index < cards.length; index++) {
              const card = cards[index];
              const cardLeft = card.offsetLeft;
              const cardWidth = card.offsetWidth;

              if (cardLeft <= centerPosition + scrollLeft && cardLeft + cardWidth >= centerPosition + scrollLeft) {
                elementInCenterIndex = index;
                break;
              }
            }

            setSelectedElement(elementInCenterIndex || 0);
          }
        };
        const containerRef = cardsContainerRef.current;
        if (containerRef) {
          containerRef.addEventListener('scroll', handleScroll);
        }
    
        return () => {
          if (containerRef) {
            containerRef.removeEventListener('scroll', handleScroll);
          }
        };
      }, []);
    
    let promoCode:any;
    
	const startCharging = () => {
		fetch(`https://api.jplug.io/set-send-startDate/${chargerSerialId}`, {
			method: "POST",
			headers: { "Content-Type": "application/json" }
		}).then(response => {
			if (!response.ok) {
			}
		}).then(data => { })
			.catch(error => {
				navigate('/404', { state: { chargerId: chargerSerialId } });
			});
	}
	const stopCharging = () => {
		fetch(`https://api.jplug.io/api/remoteStopTransaction/${chargerSerialId}`, {
			method: "POST",
			headers: { "Content-Type": "application/json" }
		}).then(response => {
			getPromoCode();
			})
			.catch(error => {
				navigate('/404', { state: { chargerId: chargerSerialId } });
			});
	}
	const getPromoCode=()=>{
			promoCode=page.context.invoice.promoCode;
			calculateAndMakeTransaction();
	}

	const calculateAndMakeTransaction = () => {
		fetch(`https://api.jplug.io/calculate-billing/${chargerSerialId}`, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		})
			.then(response => response.json())
			.then(data => {
				let time = data[0];
				let amount = data[1];
				if(promoCode===''){
				pageSend({ type: "CHANGE_INVOICE", data: { invoice:{time:time, amount:amount,tax:0.49 } }});
				doTransaction(amount+49);
				}
				if(promoCode){
					pageSend({ type: "CHANGE_INVOICE", data: { invoice:{time:time, amount:0,tax:0 } }});
				}
				navigate("/MoneyReceived", { state: { chargerId: chargerSerialId } });
			})
			.catch(error => {
				navigate('/404', { state: { chargerId: chargerSerialId } });
			});
	}

	const doTransaction = (totalAmount: any) => {
    
		fetch(`https://api.jplug.io/do-transaction/${chargerSerialId}?` + new URLSearchParams({ totalAmount: totalAmount }), {
			method: "POST",
			headers: { "Content-Type": "application/json" },
		})
			.catch(error => {
				navigate('/404', { state: { chargerId: chargerSerialId } });
			});
	}

    return(
        <div className="Home-layout" style={styles}>
            <div className="Homeprofile-notification">

                <div className="Homeprofile">
                    <SubText text="Hello 👋"></SubText>
                    <TitleText text={name}></TitleText>
                </div>

                <div className="Homenotification">
                    <Notification></Notification>
                </div>
            </div>

            <SubTitle text="Charger" url="/Chargers"></SubTitle>

            <div className='Home-Cards' ref={cardsContainerRef}>
                {charger.map((item,index)=>(
                    <CardHome status={item.status} image={item.image} type={item.type} name ={item.name} isSelect={index === selectedElement}></CardHome>
                ))}
            </div>
            <Slider active={active} setActive={setActive} activeActive={startCharging} inactiveExecute={stopCharging}></Slider>
            { /* <SecundaryButton text="Add Charger" style={{margin:"20px 0"}} icon={<Plus/>}></SecundaryButton> */}
        </div>
    )
}

export default Home;
