import React from 'react'

//icons
import Plus from 'src/icons/Plus'

//styles
import './AddChargerCard.css'
import CheckCharger from 'src/icons/CheckCharger'
import { Charger } from 'src/models/charger'

interface Props{
    charger?: Charger
    isSelect?: boolean
}

const AddChargerCard = ({charger, isSelect}:Props) => {
  return (
    <div className='AddChargerCard-Component'>
        <div>
            {charger ?<img src={charger.img} alt="Select Charger"></img> :<Plus></Plus>}
        </div>
        <span className={isSelect ? "AddChargerCard-Component-Full" : ""}>{charger ? charger.name : "Add Charger"}</span>


       <span style={{opacity:((charger && isSelect) ? "1" : "0")}}><CheckCharger></CheckCharger></span>
    </div>
  )
}

export default AddChargerCard
