import React from 'react'

const Check = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
        <path d="M4.09711 7.03563L10.6635 0.240535C10.8184 0.0801782 11.0025 0 11.2155 0C11.4286 0 11.6126 0.0801782 11.7676 0.240535C11.9225 0.400891 12 0.591314 12 0.811804C12 1.03229 11.9225 1.22272 11.7676 1.38307L4.63946 8.75947C4.4845 8.91982 4.30372 9 4.09711 9C3.8905 9 3.70971 8.91982 3.55475 8.75947L0.22314 5.3118C0.0681818 5.15145 -0.00606921 4.96102 0.000387397 4.74053C0.00684401 4.52004 0.0875517 4.32962 0.24251 4.16927C0.397469 4.00891 0.581482 3.92873 0.794551 3.92873C1.00762 3.92873 1.19163 4.00891 1.34659 4.16927L4.09711 7.03563Z" fill="#0D98E5"/>
    </svg>
  )
}

export default Check