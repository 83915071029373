import React, { useContext } from 'react';
import './DataList.css';
import { DataOptions } from '../../models/dataOptions';
import { PromisePageContext } from 'src/machines/pageMachine';


interface Props{
    label: string,
    dataoptions: DataOptions[],
    styles?: React.CSSProperties
}



const DataList = ({label, dataoptions,styles}:Props) => {
    const { page } = useContext(PromisePageContext);
    
    const styles2 = {
        color: page.context.darkMode ? "#FFFFFF" : "#0000",
      }

    return (
        <div className='DataList-layaout' style={styles}>
            <label className='DataList-list' style={styles2}>{label}</label>
            <div className='DataList-options'>
                {dataoptions.map((dataoption, index) => {
                    return <div className={(dataoption.bold === true ? "DataList-boldOption":"DataList-option")}>
                        <span>{dataoption.title}</span>
                        {dataoption.value && <span>{dataoption.value} {dataoption.type}</span>}
                    </div>
                })}
            </div>
        </div>
    )
}

export default DataList
