import React from 'react'

//styles
import './Steps.css'

//icons
import Arrow from '../../icons/Arrow'
import { useNavigate } from 'react-router-dom'

interface Props{
    numberSteps: number
    actualStep: number
    setStep: React.Dispatch<React.SetStateAction<number>>

}

const Steps = ({numberSteps, actualStep, setStep}:Props) => {
  const navigate = useNavigate();

  const backButton = () => {
    if(actualStep===1){
      navigate(-1)
    }
    else{
      setStep(actualStep-1)
    }
  }

  return (
    <div className='Steps-component'>

        <div onClick={()=>{backButton()}}>
        <Arrow></Arrow>
        </div>

        <div className='Steps-List'>
            {Array.from(Array(numberSteps).keys()).map((item, index)=>{
                return <div key={"Steps-component-"+item} className='Steps-item' style={{background:(index<=actualStep-1 ? "#141D2F" : "#E7E7E9")}}   ></div>}) }
                    
        </div>
    </div>
  )
}

export default Steps