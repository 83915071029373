import React from 'react'
import TitleText from '../TitleText/TitleText'
import SubText from '../SubText/SubText'

//style
import "./RegisterAcount.css"
import InputText from '../InputText/InputText'
import PrincipalButton from '../PrincipalButton/PrincipalButton'

interface Props{
    nextStep: () => void
}

const RegisterAcount = ({nextStep}:Props) => {

    const validateForm = () => {
        nextStep();
    }

    return (
    <div className='RegisterAcount-layout'>

      <TitleText text='Register Account' styles={{marginBottom:"26px"}}></TitleText>
      <SubText text='Enter your name, email address & password to register account.' styles={{marginBottom:"40px"}}></SubText>
    <form>
      <div className='splitDiv'>
        <div style={{width:"40%"}}>
            <InputText name='form_fields[first_name]' type='text' label='First Name' placeholder='Jiaro' styles={{marginBottom:"31px"}} />
        </div>

        <div style={{width:"40%"}}>
            <InputText name='form_fields[last_name]' type='text' label='Last Name' placeholder='Martins' styles={{marginBottom:"31px"}} />
        </div>
      </div>
      <InputText name='form_fields[email]'type='text' label='Email' placeholder='hello@ilovedrivinggreen.com' styles={{marginBottom:"31px"}} />
      <InputText type='password' label='Password' placeholder='hello@ilovedrivinggreen.com' styles={{marginBottom:"20px"}} />

      <PrincipalButton type ={"submit"}execute={validateForm} text='Continue' version='blue' styles={{marginBottom:"53px"}}></PrincipalButton>
    </form>



      <span className='simpleText'>
      By signing up you agree with our friendly <b>terms and conditions.</b>
      </span>

      <span className='simpleText'>
      Already have an account? <b><a href='/Login'>Sign in</a></b>
      </span>

    </div>
  )
}

export default RegisterAcount