import React from 'react'

//style
import "./SocialMediaButton.css"

interface Props {
    children: React.ReactNode
}

const SocialMediaButton = ({children}:Props) => {
  return (
    <button className='SocialMediaButton-Component'>
        {children}
    </button>
  )
}

export default SocialMediaButton