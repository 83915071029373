import React from 'react'
import Congrats from 'src/icons/Congrats'
import PrincipalButton from '../PrincipalButton/PrincipalButton'
import SubText from '../SubText/SubText'
import TitleText from '../TitleText/TitleText'

//styles
import './ModalCongrats.css'

const ModalCongrats = () => {

    const getStarted = () => {
    }

  return (
    <div className='ModalCongrats-Component'>
        <Congrats></Congrats>

        <TitleText text='Congratulations' styles={{marginBottom:"26px"}}></TitleText>
        <SubText text='You have completed the registration process successfully.' styles={{marginBottom:"40px"}}></SubText>
        <PrincipalButton execute={getStarted} text='Get Started' version='blue' styles={{marginBottom:"53px"}}></PrincipalButton>


    </div>
  )
}

export default ModalCongrats