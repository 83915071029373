import React from "react";
import './AccountProfile.css';
import HeaderBack from "src/components/HeaderBack/HeaderBack";
import ListOption from "src/components/ListOption/ListOption";
import ProfilePicture from "src/components/ProfilePicture/ProfilePicture";
import TitleText from "src/components/TitleText/TitleText";
import SubText from "src/components/SubText/SubText";
import Account from "src/icons/Account";
import Address from "src/icons/Address";
import RigthArrow from "src/icons/RigthArrow";
import PaymentMethod from "src/icons/PaymentMethod";
import LogOut from "src/icons/LogOut";




const AccountProfile = () => {
    return(
        <div className='AccountProfile-layout'>
            <HeaderBack title='Account Profile'></HeaderBack>
            <ProfilePicture image="/image/profile_picture.png"></ProfilePicture>
            <TitleText text='Jiaro Martins'></TitleText>
            <SubText text="hello@ilovedrivinggreen.com"></SubText>
            <ListOption label='My Account' icon={<Account/>} lastElement={<RigthArrow/>}></ListOption>
            <ListOption label='Billing Address' icon={<Address/>} lastElement={<RigthArrow/>}></ListOption>
            <ListOption label='Payment Method' icon={<PaymentMethod/>} lastElement={<RigthArrow/>}></ListOption>
            <ListOption label='Log out' icon={<LogOut/>} lastElement={<RigthArrow/>}></ListOption>
        </div>
    )
}

export default AccountProfile;