import React from 'react'

//style
import "./RegisterPaymentMethod.css"


//components
import PrincipalButton from '../PrincipalButton/PrincipalButton';
import InputText from '../InputText/InputText';
import TitleText from '../TitleText/TitleText';
import SubText from '../SubText/SubText';

//validators
import { nameText, addressText, cityText, stateText, zipText,cvvText ,cardNumberText, dateText,validator } from '../../validator/Text'
import { InputMessage } from 'src/models/inputMessage';

interface Props{
  nextStep: () => void
}


const RegisterPaymentMethod = ({nextStep}:Props) => {
  const [name,setName] = React.useState<string>("");
  const [nameMessages,setNameMessages] = React.useState<InputMessage[]>([]);

  const [address,setAddress] = React.useState<string>("");
  const [addressMessages,setAddressMessages] = React.useState<InputMessage[]>([]);

  const [city,setCity] = React.useState<string>("");
  const [cityMessages,setCityMessages] = React.useState<InputMessage[]>([]);

  const [state,setState] = React.useState<string>("");
  const [stateMessages,setStateMessages] = React.useState<InputMessage[]>([]);

  const [postalCode,setPostalCode] = React.useState<string>("");
  const [postalCodeMessages,setPostalCodeMessages] = React.useState<InputMessage[]>([]);

  const [cvv,setCvv] = React.useState<string>("");
  const [cvvMessages,setCvvMessages] = React.useState<InputMessage[]>([]);

  const [cardNumber,setCardNumber] = React.useState<string>("");
  const [cardNumberMessages, setCardNumberMessages] = React.useState<InputMessage[]>([]);
  
  const [date,setDate] = React.useState<string>("");
  const [dateMessages, setDateMessages] = React.useState<InputMessage[]>([]);



  const [promoCode,setPromoCode] = React.useState<string>("");
  

  const validateForm = () => {
    let valid=true;
    let validateName:InputMessage[]= validator(nameText,name)
    let validateAddress:InputMessage[]= validator(addressText,address)
    let validateCity:InputMessage[]= validator(cityText,city)
    let validateState:InputMessage[]= validator(stateText,state)
    let validatePostalCode:InputMessage[]= validator(zipText,postalCode)
    let validateCvv:InputMessage[]= validator(cvvText,cvv)
    let validateCardNumber: InputMessage[] = validator(cardNumberText, cardNumber);
    let validateDate: InputMessage[] = validator(dateText, date);



    if(validateName.length>0)setNameMessages(validateName);
    if(validateAddress.length>0)setAddressMessages(validateAddress);
    if(validateCity.length>0)setCityMessages(validateCity);
    if(validateState.length>0)setStateMessages(validateState);
    if(validatePostalCode.length>0)setPostalCodeMessages(validatePostalCode);
    if(validateCvv.length>0)setCvvMessages(validateCvv);
    if (validateCardNumber.length > 0) setCardNumberMessages(validateCardNumber);
    if (validateDate.length > 0) setDateMessages(validateDate);

    valid = validateName.length > 0 || validateAddress.length > 0 || validateCity.length > 0 || validateState.length > 0 || validatePostalCode.length > 0 || validateCvv.length > 0 || validateCardNumber.length > 0 || validateDate.length > 0 ? false : true;

    if(valid){
      nextStep();
    }

  }

  return (
    <div className='RegisterPaymentMethod-layout'>
      <TitleText text='Add payment method' styles={{marginBottom:"0"}}></TitleText>
      <SubText text='Please add your credit card information.' styles={{marginBottom:"40px"}}></SubText>

      <InputText messages={nameMessages} value={name} setValue={setName} type='text' label='Name on Card' placeholder='Jiaro Martins' styles={{marginBottom:"15px"}} />

      <InputText messages={cardNumberMessages} value={cardNumber} setValue={setCardNumber} type='text' pattern="[0-9\s]{13,19}" label='Card Number' placeholder='xxxx xxxx xxxx xxxx' styles={{ marginBottom: "15px" }} />


    <div className='splitDiv'>
        <div style={{width:"40%"}}>
          <InputText messages={dateMessages} value={date} setValue={setDate} type='text' label='Expiration' placeholder='MM / YY' styles={{ marginBottom: "15px" }} />
        </div>

        <div style={{width:"40%"}}>
            <InputText value={cvv} setValue={setCvv} messages={cvvMessages} hide maxLength={3} type='text' label='CVV' placeholder='. . .' styles={{marginBottom:"15px"}} />
        </div>
      </div>

    <InputText value={address} setValue={setAddress} messages={addressMessages} type='text' label='Address' placeholder='1234 Miravalle Dr' styles={{marginBottom:"15px"}} />
    <InputText value={city} setValue={setCity} messages={cityMessages} type='text' label='City' placeholder='San Francisco' styles={{marginBottom:"15px"}} />

    <div className='splitDiv'>
        <div style={{width:"40%"}}>
            <InputText value={state} setValue={setState} messages={stateMessages} type='text' label='State' placeholder='California' styles={{marginBottom:"15px"}} />
        </div>

        <div style={{width:"40%"}}>
            <InputText value={postalCode} setValue={setPostalCode} messages={postalCodeMessages} type='text' label='Postal Code' placeholder='94043' styles={{marginBottom:"15px"}} />
        </div>
      </div>
      <InputText value={promoCode} setValue={setPromoCode} type='text' label='Add Promo Code' placeholder='Promo Code' styles={{marginBottom:"15px"}} />
      <PrincipalButton execute={validateForm} text='Add' version='blue' styles={{marginBottom:"53px"}}></PrincipalButton>
    </div>
  )
}

export default RegisterPaymentMethod
