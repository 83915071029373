import React, { useContext } from 'react'

//style
import "./Layout.css"
import { PromisePageContext } from 'src/machines/pageMachine'

interface Props {
  children: React.ReactNode
}


const Layout = ({children}:Props) => {

  const { page } = useContext(PromisePageContext);

  const styles = {
    backgroundColor: page.context.darkMode ? "#141D2F" : "#FFFFFF",
    color: page.context.darkMode ? "#FFFFFF" : "#0000",
  }

  /*
  useEffect (() => {
    // pageSend ({type: "CHANGE_DARK_MODE", data:{darkMode:true } })
  }, [])
  */

  return (
    <>
    <div className={'PageSection'+ (page.context.darkMode ? " PageSection-dark" : " PageSection-light")} style={styles}>
      {children}
    </div>
    {/* <NavBar /> */}

    </>
  )
}

export default Layout
