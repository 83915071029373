import React from 'react'

//style
import "./Login.css"

import InputText from '../../components/InputText/InputText'
import TitleText from '../../components/TitleText/TitleText'
import SubText from '../../components/SubText/SubText'
import PrincipalButton from '../../components/PrincipalButton/PrincipalButton'
import Google from '../../icons/Google'
import SocialMediaButton from '../../components/SocialMediaButton/SocialMediaButton'
import Facebook from '../../icons/Facebook'

const Login = () => {
  //let options = [{order:0, value:"1", label:"1:00AM"},{order:1, value:"2", label:"2:00AM"},{order:2, value:"3", label:"3:00AM"}]

  return (
    <div className='Login-layout' >

      <TitleText text='Welcome back 👋' styles={{marginBottom:"26px"}}></TitleText>
      <SubText text='Enter your email address and password to access your account.' styles={{marginBottom:"54px"}}></SubText>

      <InputText type='text' label='Email' placeholder='hello@ilovedrivinggreen.com' styles={{marginBottom:"31px"}} />
      <InputText type='password' label='Password' placeholder='hello@ilovedrivinggreen.com' styles={{marginBottom:"20px"}} />


      <PrincipalButton text='Sign in' version='blue' styles={{marginBottom:"53px"}}></PrincipalButton>


      <div className='LoginTextSide' style={{marginBottom:"35px"}}>
        <div></div>
        <span>Or Sign in with</span>
        <div></div>
      </div>

      <div className='Login-Buttons' style={{marginBottom:"54px"}}>
        <SocialMediaButton>
          <Google/>
          <span>with Google</span>
        </SocialMediaButton>

        <SocialMediaButton>
          <Facebook/>
          <span>with Facebook</span>
        </SocialMediaButton>
      </div>

      
      <span className='simpleText'>
        Don’t have an account?
      </span>

      <span className='simpleText'> <b> <a href='/SignUp'>Register Account</a></b>   or  <b>Start as Guest</b></span>



      {/* <Steps numberSteps={5}></Steps>
      <HeaderBack title='All Settings'></HeaderBack>

      <div style={{width:"30%"}}>
        <InputTimeSelect type='text' label='Hora' placeholder='7:40PM' options={options}></InputTimeSelect>
      </div> */}

    </div>
  )
}

export default Login
