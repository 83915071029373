import React from 'react'


import './SubTitle.css'

interface Props{
    text: string
    url: string
}



const SubTitle = ({text}:Props) =>{
    return(
        <div className="SubTitle">
            <div className="title"><span>{text}</span></div>
        </div>
    )
}

export default SubTitle;