import React from 'react'

//styles
import './ChargerPhoto.css'

interface Props{
    image: string
}

const ChargerPhoto = ({image}:Props) => {
  return (
    <div className='ChargerPhoto-Component'>
        <img src={image} alt="charger" />
    </div>
  )
}

export default ChargerPhoto