import React from 'react'

//styles
import './InputCheck.css'

//models
import { Option } from '../../models/option'
import Check from '../../icons/Check'

interface Props{
    label: string
    options: Option[]
}

const InputCheck = ({label, options}:Props) => {
  return (
    <div className={(options.length===1 ? "InputCheck-componentSingle": "InputCheck-componentMultiple")}>
      
        <label className='InputCheck-label'>{label}</label>

        <div className={(options.length===1 ? "InputCheck-checksSingle": "InputCheck-checks")}>
            {options.map((option, index) => {
                return <div className='InputCheck-check'> <div> <Check></Check>  </div> {options.length>1 && <span>TH</span>} </div>
            })}
        </div>
    </div>
  )
}

export default InputCheck