import { createContext } from 'react';
import { createMachine } from "xstate";





export const pageMachine = createMachine({
    id: "pageMachine",
    initial: "authorized",
    context: { invoice: null, darkMode:false } as {invoice: any, darkMode:boolean},
    states: {
      verifying: {
        on: {
            AUTHORIZED: { target: "authorized"  },
            DENIED: { target: "denied"  },
        },
      },
      authorized: {
        on: {
          DENIED: { target: "denied" },
          CHANGE_INVOICE: {
            actions: [
              "changeInvoice"
            ]
          },
          CHANGE_DARK_MODE: {
            actions: [
              "changeDarkMode"
            ]
          }
        },
      },

      denied:{

      }
    },
  }, 
      /* actions */
  { actions: {
      // action implementations
      changeInvoice:(context, event) => {
        context.invoice = event.data.invoice;
      },

      changeDarkMode:(context, event) => {
        context.darkMode = event.data.darkMode;
      }

    }
  });

export const PromisePageContext = createContext<any>({});