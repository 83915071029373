import React from 'react'

//style
import "./RegisterSelectCharger.css"

import TitleText from '../TitleText/TitleText';
import SubText from '../SubText/SubText';
import PrincipalButton from '../PrincipalButton/PrincipalButton';
import AddChargerCard from '../AddChargerCard/AddChargerCard';
import { Charger } from 'src/models/charger';

interface Props{
    nextStep: () => void
}

const RegisterSelectCharger = ({nextStep}:Props) => {
    const validateForm = () => {
        nextStep();
    }

    let charger:Charger={
        name: 'Jiara’ Charger',
        img: '/image/charger.png',
        available: true,
    }
  
    return (
    <div className='RegisterSelectCharger-Component'>
      <TitleText text='Select your charger' styles={{marginBottom:"0"}}></TitleText>
      <SubText text='Please select your charger.' styles={{marginBottom:"40px"}}></SubText>

      <div className='RegisterSelectCharger-List'>
        <AddChargerCard></AddChargerCard>
        <AddChargerCard charger={charger} isSelect></AddChargerCard>
      </div>


      <TitleText text='Add payment method' styles={{marginBottom:"0"}}></TitleText>
      <SubText text='Please add your credit cards.' styles={{marginBottom:"40px"}}></SubText>
      <PrincipalButton execute={validateForm} text='Add Credit Card' version='blue' styles={{marginBottom:"0"}}></PrincipalButton>


    </div>
  )
}

export default RegisterSelectCharger