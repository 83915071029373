import React, { useContext } from "react";
import './AllSettings.css';
import HeaderBack from "src/components/HeaderBack/HeaderBack";
import ListOption from "src/components/ListOption/ListOption";
import Charger from "src/icons/Charger";
import SessionSummary from "src/icons/SessionSummary";
import Support from "src/icons/Support";
import RigthArrow from "src/icons/RigthArrow";
import DarkMode from "src/icons/DarkMode";
import Toggle from "react-styled-toggle";
import { PromisePageContext } from "src/machines/pageMachine";




const AllSettings = () => {
    const { page, pageSend } = useContext(PromisePageContext);

    const onchange = () => {
        pageSend ({type: "CHANGE_DARK_MODE", data:{darkMode:!(page.context.darkMode) } })
    }

    
    let component = <Toggle
    checked={page.context.darkMode}
    height={20}
    onChange={function noRefCheck(){ onchange()}}
    sliderHeight={12}
    sliderWidth={12}
    translate={19}
    width={40}
  />

    return(
        <div className='AllSettings-layout'>
            <HeaderBack title='All Settings'></HeaderBack>
            <ListOption label='My Charger Settings' icon={<Charger/>} lastElement={<RigthArrow/>}></ListOption>
            <ListOption label='Sessions Summary' icon={<SessionSummary/>} lastElement={<RigthArrow/>}></ListOption>
            <ListOption label='Email Support' icon={<Support/>} lastElement={<RigthArrow/>}></ListOption>
            <ListOption label='Darkmode' icon={<DarkMode />} lastElement={component}></ListOption>

        </div>
    )
}

export default AllSettings;