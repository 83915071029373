import React from 'react';
import './MyAccount.css';

import TitleText from 'src/components/TitleText/TitleText';
import HeaderBack from 'src/components/HeaderBack/HeaderBack';
import InputText from 'src/components/InputText/InputText';
import PrincipalButton from 'src/components/PrincipalButton/PrincipalButton';
import SubText from 'src/components/SubText/SubText';
import Key from 'src/icons/Key';

const MyAccount = () => {
    return(
        <div className='MyAccounts-layout'>
            <HeaderBack title='My Accounts'></HeaderBack>
            <TitleText text='Edit your Personal Information' styles={{marginBottom:"28px"}}></TitleText>
        <div className='splitDiv'>
            <div style={{width:"40%"}}>
                <InputText type='text' label='Fast Name' placeholder='Jiaro' styles={{marginBottom:"31px"}} />
            </div>

            <div style={{width:"40%"}}>
                <InputText type='text' label='Last Name' placeholder='Martins' styles={{marginBottom:"31px"}} />
            </div>
        </div>
        <InputText type='text' label='Email' placeholder='hello@ilovedrivinggreen.com' styles={{marginBottom:"31px"}} />
        <InputText type='password' label='Password' placeholder='hello@ilovedrivinggreen.com' styles={{marginBottom:"20px"}} />
        <div className = 'forgotPassword'>
            <Key></Key><SubText text=' Forgot Password?' styles={{marginBottom:"25px"}}></SubText>
        </div>
        

        <PrincipalButton text='Send by Email'></PrincipalButton>

            
        
        </div>
    )
}


export default MyAccount