import React from 'react'

const Account = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M1.67265 19.25C1.66912 19.2499 1.66084 19.2495 1.64849 19.2487C1.62285 19.2468 1.58134 19.2428 1.52955 19.2342C1.42242 19.2163 1.29104 19.182 1.16874 19.1208C1.04938 19.0612 0.952837 18.9834 0.884455 18.8808C0.819202 18.783 0.75 18.6188 0.75 18.3333C0.75 17.6926 1.09207 16.2186 2.40533 14.9053C3.69661 13.6141 5.9915 12.4167 10 12.4167C14.0085 12.4167 16.3034 13.6141 17.5947 14.9053C18.9079 16.2186 19.25 17.6926 19.25 18.3333C19.25 18.6188 19.1808 18.783 19.1155 18.8808C19.0472 18.9834 18.9506 19.0612 18.8313 19.1208C18.709 19.182 18.5776 19.2163 18.4704 19.2342C18.4187 19.2428 18.3771 19.2468 18.3515 19.2487C18.3452 19.2491 18.34 19.2494 18.3359 19.2496C18.3319 19.2498 18.3291 19.2499 18.3273 19.25H1.67265ZM13.0052 8.0052C12.2082 8.80223 11.1272 9.25 10 9.25C8.87283 9.25 7.79183 8.80223 6.9948 8.0052C6.19777 7.20817 5.75 6.12717 5.75 5C5.75 3.87283 6.19777 2.79183 6.9948 1.9948C7.79183 1.19777 8.87283 0.75 10 0.75C11.1272 0.75 12.2082 1.19777 13.0052 1.9948L13.5226 1.4774L13.0052 1.9948C13.8022 2.79183 14.25 3.87283 14.25 5C14.25 6.12717 13.8022 7.20817 13.0052 8.0052Z" stroke="#0D98E5" stroke-width="1.5"/>
    </svg>
  )
}

export default Account