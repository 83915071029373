import React from 'react'

const Google = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clip-path="url(#clip0_9864_2483)">
        <path d="M19.6 10.2402C19.6 9.44016 19.52 8.80016 19.36 8.16016H10V11.8402H15.52C15.44 12.8002 14.8 14.1602 13.44 15.1202V15.2802L16.4 17.6002H16.64C18.48 15.8402 19.6 13.2802 19.6 10.2402Z" fill="#2F80ED"/>
        <path d="M10 19.9999C12.72 19.9999 14.96 19.1199 16.64 17.5999L13.52 15.1199C12.64 15.6799 11.52 16.0799 10.08 16.0799C7.44 16.0799 5.19999 14.3199 4.39999 11.9199H4.31999L1.2 14.3199L1.12 14.3999C2.72 17.7599 6.08 19.9999 10 19.9999Z" fill="#27AE60"/>
        <path d="M4.32 11.9999C4.08 11.3599 4 10.7199 4 9.99994C4 9.27994 4.16 8.63994 4.32 7.99994V7.83994L1.2 5.43994L1.04 5.51994C0.4 6.87994 0 8.39994 0 9.99994C0 11.5999 0.4 13.1199 1.04 14.4799L4.32 11.9999Z" fill="#FFA412"/>
        <path d="M10 3.8402C11.84 3.8402 13.12 4.6402 13.84 5.3602L16.64 2.6402C14.88 1.0402 12.64 0.0802006 9.92004 0.0802006C6.08004 0.00020057 2.72004 2.2402 1.04004 5.5202L4.24004 8.0002C5.12004 5.6002 7.36004 3.8402 10 3.8402Z" fill="#EB5757"/>
        </g>
        <defs>
        <clipPath id="clip0_9864_2483">
        <rect width="19.6" height="20" fill="white"/>
        </clipPath>
        </defs>
    </svg>
  )
}

export default Google