import React, { useState } from 'react'

//styles
import './InputTimeSelect.css'


//Icon
import ArrowBlue from '../../icons/ArrowBlue'
import { Option } from '../../models/option'


interface Props{
  placeholder: string
  label: string
  type: string
  options: Option[]
  showLabel?:boolean
}

const InputTimeSelect = ({placeholder, label, type, options,showLabel}:Props) => {
  const [value] = useState<Option>(options[0])
  

  return (
    <div className='InputTimeSelect'>
      { showLabel && <label className="inputTimeSelect-label">{label}</label> }
      <div>
        <ArrowBlue />
        <input value={value.label} placeholder={placeholder} type={type}  ></input>
        <ArrowBlue />
      </div>
    </div>
  )
}

export default InputTimeSelect
