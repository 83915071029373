import React from 'react'

//style
import './PrincipalButton.css'
import { useNavigate } from 'react-router-dom'

interface Props {
    text: string
    version?: string
    styles?: React.CSSProperties,
    redirect?: string,
    type?: "submit",
    chargerId?: string,
    execute?: () => void
}

const PrincipalButton = ({text, version, styles,type, redirect,chargerId,execute}:Props) => {
  
  const navigate = useNavigate();
  const action=()=>{
    if(redirect){
      navigate(redirect, { state: { chargerId }});
    }

    if(execute){
      execute();
    }
  }

  return (
    <button type={type || "button"} onClick={()=>{action()}} className={'PrincipalButton '+ (version==="white" ? "WhiteVersion" : "BlueVersion" )} style={styles} >{text}</button>
    )
}

export default PrincipalButton
