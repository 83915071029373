import React from 'react';

import TitleText from 'src/components/TitleText/TitleText';
import HeaderBack from 'src/components/HeaderBack/HeaderBack';
import InputText from 'src/components/InputText/InputText';
import PrincipalButton from 'src/components/PrincipalButton/PrincipalButton';
import './BillingAddress.css';

const BillingAddress = () => {
    return(
        <div className="BillingAddress-layout">
            <HeaderBack title='Billing Address'></HeaderBack>
            <TitleText text='Your Billing Address' styles={{marginBottom:"28px"}}></TitleText>

            <InputText type='text' label='Address' placeholder='1234 Miravalle Dr' styles={{marginBottom:"15px"}} />
            <InputText type='text' label='City' placeholder='San Francisco' styles={{marginBottom:"15px"}} />

            <div className='splitDiv'>
                <div style={{width:"40%"}}>
                    <InputText type='text' label='State' placeholder='California' styles={{marginBottom:"31px"}} />
                </div>

                <div style={{width:"40%"}}>
                    <InputText type='text' label='Postal Code' placeholder='94043' styles={{marginBottom:"31px"}} />
                </div>
            </div>
            {/* Aqui va el componente de Select  */}
            <PrincipalButton text='Update'></PrincipalButton>
        </div>
    )
}


export default BillingAddress;