import React, { useContext } from 'react'
import Arrow from '../../icons/Arrow'

//styles
import './HeaderBack.css'
import { useNavigate } from 'react-router-dom'
import { PromisePageContext } from 'src/machines/pageMachine'

interface Props{
    title: string,
    urlBack?: string
}

const HeaderBack = ({title, urlBack}:Props) => {
  const navigate = useNavigate();
  const { page } = useContext(PromisePageContext);


  const backButton = () => {
    if(urlBack){
      navigate(urlBack);
    }
    else{
      navigate(-1);
    }

  }

  const styles = {
    color: page.context.darkMode ? "#FFFFFF" : "#000000",
  }

  return (
    <div className='HeaderBack' style={styles}>
        <span onClick={()=>{backButton()}}>
          <Arrow  ></Arrow>
        </span>

        <div ><span>{title}</span></div>
    </div>
  )
}

export default HeaderBack
