import React from 'react';

const Notification = () => {
    return(
<svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.8974 5.44V8.77M9.9174 1C6.2374 1 3.2574 3.98 3.2574 7.66V9.76C3.2574 10.44 2.9774 11.46 2.6274 12.04L1.3574 14.16C0.5774 15.47 1.1174 16.93 2.5574 17.41C7.3386 19 12.5061 19 17.2874 17.41C17.6029 17.3048 17.8907 17.13 18.1295 16.8986C18.3684 16.6671 18.5522 16.385 18.6674 16.073C18.7826 15.761 18.8263 15.4271 18.7951 15.096C18.7639 14.7649 18.6588 14.445 18.4874 14.16L17.2174 12.04C16.8674 11.46 16.5874 10.43 16.5874 9.76V7.66C16.5774 4 13.5774 1 9.9174 1Z" stroke="#141D2F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M13.2274 17.8198C13.2274 19.6498 11.7274 21.1498 9.8974 21.1498C8.9874 21.1498 8.1474 20.7698 7.5474 20.1698C6.9474 19.5698 6.5674 18.7298 6.5674 17.8198" stroke="#141D2F" stroke-width="1.5" stroke-miterlimit="10"/>
</svg>
    )
}

export default Notification;