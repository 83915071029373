import React from 'react'

//style
import "./SubText.css"

interface Props {
    text: string
    styles?: React.CSSProperties
}

const SubText = ({text, styles}:Props) => {
  return (
    <span className='SubText' style={styles}>{text}</span>
  )
}

export default SubText