import React from "react";
import './Notifications.css';
import HeaderBack from "src/components/HeaderBack/HeaderBack";
import Notification from "src/components/Notification/Notification";

const Notifications = () => {
    return(
        <div className="Notifications-layout">
            <HeaderBack title="Notificaciones"/>
            <div className="Notifications-ListOptions">
                <Notification text='Congrats!! You made 18 CAD from sharing your EV Charger' type="success"></Notification>
                <Notification text='Alert! Your charger is not connected to the internet' type="warning"></Notification>
                <Notification text='Congrats!! Mike has shared his EV Charger with you.' type="success"></Notification>
                <Notification text='Alert! Your charger is not connected to the internet' type="warning"></Notification>
                <Notification text='Congrats!! Mike has shared his EV Charger with you.' type="success"></Notification>
                <Notification text='Charging sharing session finished. Please move your EV.' type="warning"></Notification>
                <Notification text='Congrats!! Mike has shared his EV Charger with you.' type="success"></Notification>
            </div>

        </div>
    )
}

export default Notifications;