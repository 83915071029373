import React from 'react'

//styles
import './ListOption.css'

interface Props{
    label: string,
    icon: React.ReactElement,
    lastElement: React.ReactElement,

}


const ListOption = ({label, icon, lastElement}:Props) => {
  return (
    <div className='ListOption-Component'>

      <div className='ListOption-labels'>
        <div>{icon}</div>
        <div>{label}</div>
      </div>
        
        {lastElement}

    </div>
  )
}

export default ListOption