import React, { useState } from 'react'
import ModalCongrats from 'src/components/ModalCongrats/ModalCongrats';
import RegisterAcount from 'src/components/RegisterAcount/RegisterAcount';
import RegisterAddCharger from 'src/components/RegisterAddCharger/RegisterAddCharger';
import RegisterPaymentMethod from 'src/components/RegisterPaymentMethod/RegisterPaymentMethod';
import RegisterPlugTime from 'src/components/RegisterPlugTime/RegisterPlugTime';
import RegisterSelectCharger from 'src/components/RegisterSelectCharger/RegisterSelectCharger';
import Steps from 'src/components/Steps/Steps'

const SignUp = () => {
  const [step,setStep] = useState<number>(1);
  //const [active,setActive] = useState<boolean>(false);


  const nextStep = () => {
    setStep(step+1)
  }

  return (
    <div>
      <Steps numberSteps={5} actualStep={step} setStep={setStep}></Steps>

      {step===1 ? 
      <RegisterAcount nextStep={nextStep} /> 
      
      : step===2 ?
      <RegisterPlugTime nextStep={nextStep} />

      : step===3 ?
      <RegisterAddCharger nextStep={nextStep} />

      : step===4 ?
      <RegisterSelectCharger nextStep={nextStep} />

      : step===5 ?
      <RegisterPaymentMethod nextStep={nextStep} />
      
      :   <></>}

      {step===6 && <ModalCongrats></ModalCongrats>}

    </div>
  )
}

export default SignUp
