import React from 'react'

const CheckCharger = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
        <path d="M13.5647 0.0384159C9.80536 0.575457 7.1301 1.78877 4.72335 4.06623C0.446906 8.11394 -1.08466 14.2004 0.785044 19.7797C1.94863 23.2605 4.43494 26.264 7.68703 28.1138C11.874 30.4907 17.0853 30.6299 21.6302 28.4818C24.4945 27.1193 27.0703 24.5136 28.5521 21.4604C30.0737 18.3177 30.4318 14.7375 29.5566 11.2765C29.2383 10.0234 29.0096 9.51621 28.6416 9.29742C28.1046 8.97917 27.309 9.15819 27.0703 9.6455C26.8415 10.103 26.8415 10.3019 27.0802 11.1273C27.8659 13.8225 27.8659 16.2989 27.0802 18.7852C25.9663 22.3555 23.2612 25.2993 19.8003 26.7115C19.3528 26.8905 18.5472 27.159 18.0102 27.3082C17.1151 27.5469 16.8963 27.5668 15.0067 27.5668C13.1271 27.5668 12.8983 27.5469 12.0231 27.3082C10.6507 26.9402 9.56668 26.4927 8.5821 25.9059C5.60848 24.1059 3.60949 21.4206 2.70447 17.9995C2.35639 16.6668 2.26688 14.2601 2.52546 12.828C3.36086 8.15372 6.7124 4.33475 11.2076 2.92253C12.4806 2.52472 13.6243 2.35565 15.0564 2.35565C17.5229 2.35565 19.7804 3.01204 21.7894 4.31486C22.3165 4.653 22.6446 4.79223 22.9828 4.83201C23.4204 4.87179 23.48 4.8519 23.8082 4.52371C24.1066 4.22536 24.1563 4.10601 24.1563 3.72809C24.1563 3.15127 23.8878 2.82308 22.9331 2.22636C21.5009 1.3313 19.8003 0.645075 18.0599 0.267155C17.1748 0.0682507 14.3404 -0.070982 13.5647 0.0384159Z" fill="#0D98E5"/>
        <path d="M25.9271 5.08318C25.6487 5.19258 25.1812 5.71968 19.9202 11.7863C17.4339 14.6505 15.3653 17.0075 15.3355 17.0075C15.3056 17.0174 14.2216 16.1224 12.9188 15.0383C11.5762 13.9145 10.4424 13.0394 10.2932 13.0095C9.90536 12.9399 9.34843 13.1985 9.13958 13.5466C8.83128 14.0637 8.97051 14.4019 10.2037 16.1522C10.8303 17.0373 11.5961 18.1413 11.9143 18.6087C12.2325 19.0761 12.869 19.9513 13.3265 20.5679C14.0127 21.4928 14.2315 21.7116 14.6691 21.9304C14.9476 22.0696 15.3454 22.189 15.5344 22.189C16.0018 22.179 16.6979 21.8608 17.1256 21.463C17.3046 21.2839 18.3588 19.7822 19.4727 18.1114C20.5766 16.4406 22.7844 13.1189 24.3757 10.7221C26.2255 7.94741 27.2797 6.27661 27.2797 6.11749C27.2797 5.71968 27.0211 5.32187 26.6432 5.14285C26.2653 4.96384 26.2255 4.96384 25.9271 5.08318Z" fill="#0D98E5"/>
    </svg>
  )
}

export default CheckCharger