import React from 'react'

const EyesOpen = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none">
        <path d="M13.1133 8.50448C13.1133 10.299 11.6632 11.7492 9.86861 11.7492C8.07405 11.7492 6.6239 10.299 6.6239 8.50448C6.6239 6.70992 8.07405 5.25977 9.86861 5.25977C11.6632 5.25977 13.1133 6.70992 13.1133 8.50448Z" stroke="#85898F" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.86858 16C13.068 16 16.0498 14.1148 18.1254 10.852C18.9411 9.57402 18.9411 7.42598 18.1254 6.14804C16.0498 2.8852 13.068 1 9.86858 1C6.66918 1 3.68731 2.8852 1.61178 6.14804C0.796073 7.42598 0.796073 9.57402 1.61178 10.852C3.68731 14.1148 6.66918 16 9.86858 16Z" stroke="#85898F" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default EyesOpen