import React from 'react';
import './SessionSummary.css';

import SubText from 'src/components/SubText/SubText';
import HeaderBack from 'src/components/HeaderBack/HeaderBack';
import DataList from 'src/components/DataList/DataList';
import PrincipalButton from 'src/components/PrincipalButton/PrincipalButton';


const SessionSummary = () => {

    let jun = [{title:'01',value:2.34, type:'CAD',bold: false},{title:'02',value:5.60, type:'CAD',bold: false},{title:'03',value:'+1.50', type:'CAD',bold: true},{title:'04',value:3.00, type:'CAD',bold: false},{title:'05',value:6.21, type:'CAD',bold: false}]
    let may = [{title:'01',value:2.34, type:'CAD',bold: false},{title:'02',value:'+5.60', type:'CAD',bold: true},{title:'03',value:1.50, type:'CAD',bold: false},{title:'04',value:'+3.00', type:'CAD',bold: true},{title:'05',value:6.21, type:'CAD',bold: false},{title:'06',value:2.34, type:'CAD',bold: false}]

    return(
        <div className='SessionSummary-layout'>
            <HeaderBack title='Sessions Summary'></HeaderBack>
            <SubText text='You have earned' styles={{marginTop:"80px", marginBottom:"20px"}}></SubText>
            <PrincipalButton text='19.50 CAD'></PrincipalButton>
            <SubText text='While not using your charger' styles={{marginBottom:"60px",marginTop:"20px"}}></SubText>
            <DataList label="June '23" dataoptions={jun}></DataList>
            <DataList label="June '23" dataoptions={may}></DataList>
        </div>
    )

}


export default SessionSummary