import React, { useContext } from 'react';
import './MoneyReceived.css';

import TitleText from 'src/components/TitleText/TitleText';
import HeaderBack from 'src/components/HeaderBack/HeaderBack';
import DataList from 'src/components/DataList/DataList';
import { PromisePageContext } from 'src/machines/pageMachine';
import { useLocation } from 'react-router-dom';

const MoneyReceived = () => {
    const { page } = useContext(PromisePageContext);
    let tax = page.context.invoice.tax;
    let amount=page.context.invoice.amount;
    let total = (amount/100)+tax;
    const location = useLocation();
    const { chargerId } = location.state || {};
    let chargerSerialId = chargerId.chargerId; //extract from property

    let session = [{title:'Rate',value:1.50, type:'CAD / hr',bold: false},{title:'Time',value:page.context.invoice.time, type:'hrs',bold: false},{title:'Rate',value:amount/100, type:'CAD / hr',bold: false}]
    let taxes = [{title:'HST',value:tax, type:'CAD',bold: false},{title:'Total',value:total, type:'CAD',bold: true}]
    let energy = [{title:'AVG Charging Power',value:3.50, type:'Kw',bold: false},{title:'Energy',value:8.75, type:'Kwh',bold: false}]
    let date = [{title:'2023 - 07 - 21',value:null, type:'CAD / hr',bold: false}]

    const urlBack = `/${chargerSerialId}`;

    const styles = {
        backgroundColor: page.context.darkMode ? "#141D2F" : "#FFFFFF",
        color: page.context.darkMode ? "#FFFFFF" : "#000000",
      }

    return(
        <div className='MoneyReceived-layout' style={styles}>
            {/*<label>{page.context.invoice?.summary} {page.context.invoice?.time}</label>*/}
            <HeaderBack urlBack={urlBack}  title='Money Received'></HeaderBack>
            <TitleText text='Thank you for charging with us' styles={{marginBottom:"28px"}}></TitleText>
            <DataList label='Session Summary' dataoptions={session}></DataList>
            <DataList label='Taxes' dataoptions={taxes}></DataList>
            <DataList label='Energy Added' dataoptions={energy}></DataList>
            <DataList label='Date' dataoptions={date} styles={{marginBottom:"75px"}}></DataList>
        </div>
    )
}
export default MoneyReceived
