import React from 'react'

//styles
import './Notification.css'
import Success from 'src/icons/Success'
import Warning from 'src/icons/Warning'


interface Props{
    text: string
    type: 'success' | 'error' | 'warning'
}


const Notification = ({text, type}:Props) => {
  return (
    <div className='Notification-Component'>
        <div style={{background:(type==="success" ? "#EBF8F2" : type==="error" ? "" : "#FEF8EE" )}}>
            {type==="success"?
             <Success/> :
             type==="error"?
             <></> :
             <Warning></Warning>
            }
        </div>
        
        <span>{text}</span>
    </div>
  )
}

export default Notification