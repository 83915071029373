import React from 'react'

//style
import "./RegisterPlugTime.css"

//components
import TitleText from '../TitleText/TitleText'
import SubText from '../SubText/SubText'
import PrincipalButton from '../PrincipalButton/PrincipalButton'
import InputCheck from '../InputCheck/InputCheck'
import RangeTime from '../RangeTime/RangeTime'


interface Props{
    nextStep: () => void
}

const RegisterPlugTime = ({nextStep}:Props) => {
    
    const validateForm = () => {
        nextStep();
    }

    let options = [{order:0, value:"1", label:"1:00AM"},{order:0, value:"1", label:"1:00AM"},{order:0, value:"1", label:"1:00AM"},{order:0, value:"1", label:"1:00AM"},{order:0, value:"1", label:"1:00AM"}]

    
    return (
    <div className='RegisterPlugTime-layout'>

      <TitleText text='When will you plug?' styles={{marginBottom:"26px"}}></TitleText>
      <SubText text='This information will be used to provide you with the best EV Charging Experience. You will be able to charge at anytime, regardless of your answer.' styles={{marginBottom:"20px"}}></SubText>

        <RangeTime label='Overnight'></RangeTime>
        <RangeTime label='Evening'></RangeTime>
        <RangeTime label='Afternoon'></RangeTime>


        <InputCheck label={"Days"} options={options} ></InputCheck>




      <PrincipalButton execute={validateForm} text='Continue' version='blue' styles={{marginTop:"20px"}}></PrincipalButton>



    </div>
  )
}

export default RegisterPlugTime
