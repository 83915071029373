import React from 'react'

interface Props {
  onClick?: () => void
}

const Arrow = ({onClick}:Props) => {
  

  
  return (
    <svg onClick={()=>{if(onClick)onClick()}} xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <g clip-path="url(#clip0_9870_393)">
        <path d="M15.9788 8L1.97876 8" stroke="#85898F" stroke-width="1.712" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.97876 15L1.97876 8L8.97876 0.999998" stroke="#85898F" stroke-width="1.712" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_9870_393">
        <rect width="16" height="16" fill="white" transform="translate(16.9788 16) rotate(-180)"/>
        </clipPath>
        </defs>
    </svg>
  )
}

export default Arrow