import React from 'react'

//styles
import './ProfilePicture.css'

interface Props{
    image: string
}

const ProfilePicture = ({image}:Props) => {
  return (
    <div className='ProfilePicture-Component'>
        <img src={image} alt='client'></img>
    </div>
  )
}

export default ProfilePicture