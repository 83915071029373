import React from 'react'

const RigthArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
        <path d="M1 15L8 8L0.999999 0.999998" stroke="#85898F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default RigthArrow