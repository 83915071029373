import React from 'react';

import './ChargerSettings.css'

import HeaderBack from 'src/components/HeaderBack/HeaderBack';
import RangeTime from 'src/components/RangeTime/RangeTime';
import InputTimeSelect from 'src/components/InputTimeSelect/InputTimeSelect';
import InputCheck from 'src/components/InputCheck/InputCheck';
import ChargerPhoto from 'src/components/ChargerPhoto/ChargerPhoto';
import SliderBasic from 'src/components/SliderBasic/SliderBasic';
import SubText from 'src/components/SubText/SubText';



const ChargerSettings = () => {

    const [active,setActive] = React.useState<boolean>(true);
    let options = [{order:0, value:"1", label:"1:00AM"},{order:0, value:"1", label:"1:00AM"},{order:0, value:"1", label:"1:00AM"},{order:0, value:"1", label:"1:00AM"},{order:0, value:"1", label:"1:00AM"}]
    return(
        <div className="ChargerSettings-layout">
            <HeaderBack title="Charger Settings" />

            <div className="chargerActive">
                <ChargerPhoto image="/image/charger.png"></ChargerPhoto>
                <div className="sliderContent">
                    <SubText text='Enable Sharing >>>>>'></SubText>
                    <div style={{width:"115px"}}>
                        <SliderBasic active={active} setActive={setActive}></SliderBasic>
                    </div>
                    
                    <SubText text='<<<<< Set to Private'></SubText>
                </div> 
            </div>
            <div className="TimeCharger-form">
                <RangeTime label="Hours" noShowCheck></RangeTime>
                <InputTimeSelect type='text' label='Hora' placeholder='7:40PM' options={options} showLabel></InputTimeSelect>
            </div>
            <InputCheck label='Days' options={options}></InputCheck>


    
        </div>
    )
};

export default ChargerSettings;
