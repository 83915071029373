import React from 'react'
import HeaderBack from 'src/components/HeaderBack/HeaderBack'
import SubTitle from 'src/components/SubTitle/SubTitle'

import './Charger.css'
import SecundaryButton from 'src/components/SecundaryButton/SecundaryButton'
import Plus from 'src/icons/Plus'
import ChargerCard from 'src/components/ChargerCard/ChargerCard'




const Charger = () => {

    let charger = [{name: 'Charger 1', img: '/image/charger.png', available:true},{name: 'Charger 1', img: '/image/charger.png', available:true},{name: 'Charger 1', img: '/image/charger.png', available:true}]

    return(
        <div className='Charger-layout'>
            <HeaderBack title='Chargers'/>
            <SecundaryButton icon={<Plus></Plus>}></SecundaryButton>
            <SubTitle text="Chargers" url="/Chargers"></SubTitle>
            <div className='Charger-title'>
                {charger.map((charger,index) => {
                    return(
                        <ChargerCard  charger={charger}></ChargerCard>
                    )
                })}
            </div>

            

        </div>
    )
}


export default Charger;
