import React from 'react'

//styles
import './SecundaryButton.css'

interface Props{
    text?: string,
    icon?: React.ReactElement,
    style?: React.CSSProperties,
}

const SecundaryButton = ({text, icon, style}:Props) => {
  return (
    <button className='SecundaryButton' style={style}>
        {icon}
        {text}
    </button>
  )
}

export default SecundaryButton