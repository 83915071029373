import React from 'react'

interface Props {
  darkMode?: boolean
}

const Congrats = ({darkMode}:Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="204" height="180" viewBox="0 0 204 180" fill="none">
    <path d="M67.0505 13.3488C64.5914 13.9801 61.5519 14.8974 59.7255 16.7459C57.4999 18.9972 56.6808 22.4291 55.954 25.8506C55.8843 26.1802 55.4312 26.1802 55.3614 25.8506C54.7741 23.1092 54.0195 19.8168 52.4318 17.7817C50.4118 15.1921 47.3009 14.1336 44.2475 13.3488C43.9286 13.2669 43.9286 12.8309 44.2475 12.7489C46.9192 12.0636 49.9796 11.155 51.8897 9.05369C53.8818 6.86166 54.6817 3.42625 55.3614 0.247192C55.4312 -0.0823974 55.8843 -0.0823974 55.954 0.247192C56.5884 3.23443 57.6724 6.62798 59.478 8.75724C61.4386 11.0696 64.2097 12.02 67.0505 12.7489C67.3694 12.8292 67.3694 13.2669 67.0505 13.3488Z" fill="#0D98E5"/>
    <path d="M203.239 136.149C200.78 136.78 197.741 137.697 195.914 139.546C193.689 141.797 192.87 145.229 192.143 148.65C192.073 148.98 191.62 148.98 191.55 148.65C190.963 145.909 190.208 142.617 188.621 140.582C186.601 137.992 183.49 136.933 180.436 136.149C180.118 136.067 180.118 135.631 180.436 135.549C183.108 134.863 186.169 133.955 188.079 131.854C190.071 129.661 190.871 126.226 191.55 123.047C191.62 122.717 192.073 122.717 192.143 123.047C192.777 126.034 193.861 129.428 195.667 131.557C197.628 133.869 200.399 134.82 203.239 135.549C203.558 135.629 203.558 136.067 203.239 136.149Z" fill="#0D98E5"/>
    <path d="M32.9314 71.4674C29.4179 72.369 25.073 73.6803 22.4623 76.3223C19.2816 79.5415 18.1122 84.4452 17.0735 89.3332C16.9742 89.8041 16.3276 89.8058 16.2265 89.3332C15.3882 85.4148 14.3076 80.7099 12.0385 77.8011C9.1524 74.1006 4.70648 72.587 0.342463 71.4674C-0.114154 71.3506 -0.114154 70.7262 0.342463 70.6094C4.15923 69.6311 8.5337 68.3319 11.2647 65.3273C14.1107 62.1953 15.254 57.2863 16.2265 52.7418C16.3276 52.271 16.9742 52.271 17.0735 52.7418C17.9815 57.0108 19.5291 61.8605 22.1102 64.9035C24.9127 68.2081 28.8741 69.5666 32.9314 70.6077C33.388 70.7263 33.388 71.3506 32.9314 71.4674Z" fill="#0D98E5"/>
    <path d="M195.384 37.986C194.192 38.2912 192.718 38.7376 191.831 39.6339C190.752 40.7256 190.355 42.391 190.001 44.0511C189.966 44.2116 189.748 44.2116 189.713 44.0511C189.429 42.7206 189.061 41.1249 188.291 40.1362C187.312 38.8806 185.802 38.3661 184.321 37.986C184.166 37.9459 184.166 37.7349 184.321 37.6948C185.618 37.3617 187.101 36.9222 188.028 35.9021C188.993 34.8383 189.382 33.1729 189.711 31.6296C189.746 31.4692 189.964 31.4692 189.999 31.6296C190.308 33.0788 190.832 34.725 191.709 35.7573C192.66 36.8786 194.004 37.3407 195.383 37.693C195.539 37.7331 195.539 37.9459 195.384 37.986Z" fill="#0D98E5"/>
    <path d="M63.5962 142.499C62.4042 142.804 60.9297 143.251 60.0426 144.147C58.9638 145.239 58.5665 146.904 58.2127 148.564C58.1778 148.725 57.96 148.725 57.9251 148.564C57.641 147.234 57.2733 145.638 56.503 144.649C55.5235 143.394 54.0142 142.879 52.5328 142.499C52.3777 142.459 52.3777 142.248 52.5328 142.208C53.8277 141.875 55.3126 141.435 56.2398 140.415C57.2053 139.351 57.594 137.686 57.9234 136.143C57.9582 135.982 58.1761 135.982 58.2109 136.143C58.5194 137.592 59.044 139.238 59.9206 140.271C60.8722 141.392 62.2159 141.854 63.5945 142.206C63.7513 142.248 63.7513 142.459 63.5962 142.499Z" fill="#0D98E5"/>
    <path d="M105.922 173.815C104.73 174.12 103.255 174.566 102.368 175.463C101.29 176.554 100.892 178.22 100.538 179.88C100.503 180.04 100.286 180.04 100.251 179.88C99.9667 178.549 99.599 176.954 98.8287 175.965C97.8492 174.709 96.3399 174.195 94.8585 173.815C94.7034 173.774 94.7034 173.563 94.8585 173.523C96.1534 173.19 97.6383 172.751 98.5655 171.731C99.531 170.667 99.9197 169.002 100.249 167.458C100.284 167.298 100.502 167.298 100.537 167.458C100.845 168.907 101.37 170.554 102.246 171.586C103.198 172.707 104.542 173.169 105.92 173.522C106.075 173.563 106.075 173.774 105.922 173.815Z" fill="#0D98E5"/>
    <mask id="mask0_9870_811" style={{maskType:"luminance"}}  maskUnits="userSpaceOnUse" x="45" y="13" width="137" height="137">
    <path d="M113.625 149.851C151.241 149.851 181.735 119.339 181.735 81.7009C181.735 44.0626 151.241 13.5508 113.625 13.5508C76.0097 13.5508 45.5161 44.0626 45.5161 81.7009C45.5161 119.339 76.0097 149.851 113.625 149.851Z" fill="#fff"/>
    </mask>
    <g mask="url(#mask0_9870_811)">
    <rect x="7.78027" y="-24.3037" width="205.834" height="206.124" fill="#0D98E5"/>
    </g>
    <path d="M113.626 152.192C74.78 152.192 43.1758 120.569 43.1758 81.7003C43.1758 42.8314 74.78 11.21 113.626 11.21C152.471 11.21 184.076 42.8332 184.076 81.7021C184.076 120.571 152.471 152.192 113.626 152.192ZM113.626 15.894C77.3611 15.894 47.857 45.4158 47.857 81.7021C47.857 117.988 77.3611 147.51 113.626 147.51C149.89 147.51 179.394 117.988 179.394 81.7021C179.394 45.4158 149.89 15.894 113.626 15.894Z" fill="#0D98E5"/>
    <g opacity="0.56">
    <mask id="mask1_9870_811" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="73" y="46" width="82" height="74">
    <path d="M151.342 49.8818C147.283 45.7314 138.747 44.9676 134.791 49.8818C127.981 58.3378 121.172 66.7938 114.363 75.2498C111.121 79.2763 107.878 83.3029 104.636 87.3295C103.486 88.7594 102.116 90.9027 100.623 92.8941C100.332 92.5942 100.039 92.2925 99.746 91.9926C97.5448 89.729 95.3454 87.4672 93.1442 85.2037C82.6194 74.3813 66.0748 90.9515 76.591 101.765C82.0216 107.347 87.5672 115.153 94.9289 118.098C103.002 121.328 110.163 117.441 115.353 111.14C127.513 96.3784 139.35 81.3393 151.344 66.4433C155.337 61.482 156.097 54.7454 151.342 49.8818Z" fill="white"/>
    </mask>
    <g mask="url(#mask1_9870_811)">
    <rect x="73.1992" y="46.4683" width="81.4383" height="72.8237" fill="#0D98E5"/>
    </g>
    </g>
    <path d="M150.741 47.5454C146.682 43.395 138.146 42.6312 134.19 47.5454C127.38 56.0014 120.571 64.4573 113.762 72.9133C110.52 76.9399 107.277 80.9665 104.035 84.993C102.885 86.423 101.515 88.5662 100.022 90.5577C99.7305 90.2578 99.4377 89.9561 99.145 89.6561C96.9438 87.3926 94.7443 85.1308 92.5432 82.8673C82.0183 72.0449 65.4737 88.615 75.9899 99.4287C81.4205 105.011 86.9661 112.816 94.3278 115.762C102.401 118.991 109.562 115.104 114.752 108.804C126.912 94.0419 138.749 79.0029 150.743 64.1068C154.737 59.1455 155.497 52.409 150.741 47.5454Z" fill={darkMode ? "#141D2F":"white"}/>
    <path d="M133.711 19.6333C118.303 13.0328 75.935 11.4389 54.3955 53.4782C74.6557 29.1496 96.603 16.8588 133.711 19.6333Z" fill={darkMode ? "#141D2F":"white"}/>
    <path d="M99.4914 145.657C135.28 143.069 160.033 122.118 177.158 96.8481C174.391 112.599 163.754 127.064 150.866 135.965C137.978 144.865 117.963 150.289 99.3188 145.657" fill={darkMode ? "#141D2F":"white"}/>
    </svg>
  )
}

export default Congrats