import React from 'react'

//styles
import './InputText.css'
import EyesOpen from '../../icons/EyesOpen'
import { InputMessage } from 'src/models/inputMessage'

interface Props{
  placeholder: string
  label: string
  type: string,
  styles?: React.CSSProperties
  value?: string,
  setValue?: React.Dispatch<React.SetStateAction<string>>,
  maxLength?: number,
  minLength?: number,
  messages?: InputMessage[]
  hide?: boolean
  pattern?: string,
  name?: string
}

const InputText = ({placeholder, label, type, styles,name, maxLength, minLength, value, setValue, messages, hide, pattern}:Props) => {


  const handleInput = () => {

  }

  const change= (e:string)=>{
    if(setValue)
    setValue(e);

  }

  return (
    <div className='InputText-Component' style={styles}>
      <label>{label}</label>
      <div>
        <input name={name} pattern={pattern} value={value} maxLength={maxLength} min={minLength} placeholder={placeholder} type={hide ? "password" :type } onChange={(e)=>{change(e.target.value)}}  ></input>
        {type==="password" && <div onClick={()=>{ handleInput()}}><EyesOpen></EyesOpen></div>}
      </div>

      {messages && messages.map((message,index)=>{
        if(index>=1) return <></>
        return <p key={index} className='InputText-message'>{message.message}</p>
      }

      )}
    </div>
  )
}

export default InputText