import React,{useContext} from 'react'

import './CongratsView.css'
import TitleText from 'src/components/TitleText/TitleText'
import SubText from 'src/components/SubText/SubText'
import PrincipalButton from 'src/components/PrincipalButton/PrincipalButton'
import Congrats from 'src/icons/Congrats'
import { PromisePageContext } from 'src/machines/pageMachine';
import { useNavigate, useLocation } from 'react-router-dom';

const CongratsView = () => {
const { page, pageSend } = useContext(PromisePageContext);
 const navigate = useNavigate();
 const location = useLocation();
 const chargerId = location.state || {};
 let chargerSerialId = chargerId.chargerId; //Since it was passed through navigate, we need to extract throught properties.
 
 const sendData = ()=>{
	 pageSend ({type: "CHANGE_INVOICE", data:{invoice: {promoCode:page.context.invoice.promoCode}}})
	 navigate('/Home', { state: { chargerId: chargerSerialId }});
}
  return (
    <div className='CongratsView-Component'>
        <Congrats darkMode={page.context.darkMode}></Congrats>

        <TitleText text='Congratulations' styles={{marginBottom:"26px"}}></TitleText>
        <SubText text='You have completed the registration process successfully.' styles={{marginBottom:"40px"}}></SubText>
        <PrincipalButton execute={sendData} text='Get Started' version='blue' styles={{marginBottom:"53px", width: "100%"}}></PrincipalButton>

    </div>
  )
}

export default CongratsView
