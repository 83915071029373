import React from 'react'

//styles
import './RangeTime.css'

//components
import InputCheck from '../InputCheck/InputCheck'
import InputTimeSelect from '../InputTimeSelect/InputTimeSelect'

let options = [{order:0, value:"1", label:"1:00AM"}]

interface Props{
    label: string
    noShowCheck?:boolean
}

const RangeTime = ({label,noShowCheck}:Props) => {
  return (
    <div className='RangeTime-Component'>
      { !noShowCheck ? <InputCheck label={label} options={options} ></InputCheck> : <label className="rangeTimeSubTitle">{label}</label>}
      <div className='RangeTime-Time'>
          <div style={{width:"40%"}}>
              <InputTimeSelect type='text' label='Hora' placeholder='7:40PM' options={options}></InputTimeSelect>
          </div>

          <span>TO</span>

          <div style={{width:"40%"}}>
            <InputTimeSelect type='text' label='Hora' placeholder='7:40PM' options={options}></InputTimeSelect>
          </div>
      </div>

    </div>
  )
}

export default RangeTime