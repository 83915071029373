import React from 'react'

//style
import "./RegisterAddCharger.css"

//component
import PrincipalButton from '../PrincipalButton/PrincipalButton';
import TitleText from '../TitleText/TitleText';
import SubText from '../SubText/SubText';
import QR from 'src/icons/QR';


interface Props{
    nextStep: () => void
}




const RegisterAddCharger = ({nextStep}:Props) => {
    const validateForm = () => {
        nextStep();
    }

  return (
    <div className='RegisterAddCharger-Component'>
      <TitleText text='Add your Charger' styles={{marginBottom:"0"}}></TitleText>
      <SubText text='Please scan the QR Code in the charger' styles={{marginBottom:"40px"}}></SubText>

      <QR></QR>
      <SubText text='Scan Charger QR Code' styles={{marginBottom:"40px"}}></SubText>

      <PrincipalButton execute={validateForm} text='Continue' version='blue' styles={{marginTop:"20px"}}></PrincipalButton>



    </div>
  )
}

export default RegisterAddCharger