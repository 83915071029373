import React, { useCallback, useEffect, useState } from 'react'

//style
import './Slider.css'
import ArrowBlue from 'src/icons/ArrowBlue'
import Logo from 'src/icons/Logo'

interface Props {
    active: boolean,
    activeActive?: () => void,
    inactiveExecute?: () => void,
    setActive: React.Dispatch<React.SetStateAction<boolean>>
    darkMode?: boolean
}

const Slider = ({active, setActive, activeActive, inactiveExecute}:Props) => {
  const [animate,setAnimate] = useState<number>(1);
  //const [offText,setOffText] = useState<string>(active ? "Slider-Component-Text-OFF" : "Slider-Component-Text-ON");
  //const [onText,setOnText] = useState<string>(active ? "Slider-Component-Text-ON" : "Slider-Component-Text-OFF");

  const animateSlider = useCallback((value: boolean) => {
    setAnimate(prevAnimate => prevAnimate + 1);
    setActive(value);
  }, [setActive]);

useEffect(() => {
  if(animate===1) return;
    if(active){
        activeActive && activeActive();
    }
    else{
        inactiveExecute && inactiveExecute();
    }
}, [active, activeActive, animate, inactiveExecute]);



useEffect(() => {
    const circulo = document.getElementById('miCirculo');
    const contenedor = document.querySelector('.contenedor');
    let isDragging = false;
    let initialX = 0;
    if(!circulo || !contenedor) return;

    circulo.addEventListener('touchstart', (e) => {
      isDragging = true;
      initialX = e.touches[0].clientX;
    });

    contenedor.addEventListener('touchmove', (e:any) => {
      if (isDragging) {
        e.preventDefault();
        const contenedorRect = contenedor.getBoundingClientRect();
        const circuloRect = circulo.getBoundingClientRect();

        const touchX = e.touches[0].clientX - contenedorRect.left;
        const circuloX = touchX - circuloRect.width / 2;

        const minX = 0;
        const maxX = contenedorRect.width - circuloRect.width;

        const clampedX = Math.min(maxX, Math.max(minX, circuloX));

        circulo.style.left = clampedX + 'px';
        if(clampedX<= 7){
            animateSlider(false);
        }
        else if(clampedX>171){
            animateSlider(true);
        }
      }
    });

    contenedor.addEventListener('touchend', (e:any) => {
      isDragging = false;


      if (initialX < 171 && initialX > 7) {
        // circulo.style.left = '7px'; // Reset to the beginning
         // Set active to its initial state
      }
      

    });
}, [animateSlider]);



useEffect(() => {
    const circulo = document.getElementById('miCirculo');
    const contenedor = document.querySelector('.contenedor');
    let isDragging = false;
    if(!circulo || !contenedor) return;

    circulo.addEventListener('touchstart', (e) => {
      isDragging = true;
    });

    contenedor.addEventListener('touchmove', (e:any) => {
      if (isDragging) {
        e.preventDefault();
        const contenedorRect = contenedor.getBoundingClientRect();
        const circuloRect = circulo.getBoundingClientRect();

        const touchX = e.touches[0].clientX - contenedorRect.left;
        const circuloX = touchX - circuloRect.width / 2;

        const minX = 0;
        const maxX = contenedorRect.width - circuloRect.width;

        const clampedX = Math.min(maxX, Math.max(minX, circuloX));

        circulo.style.left = clampedX + 'px';
        if(clampedX<= 7){
            animateSlider(false);
        }
        else if(clampedX>171){
            animateSlider(true);
        }
      }
    });

    contenedor.addEventListener('touchend', () => {
      isDragging = false;
    });
}, [animateSlider]);



  return (
    <>

    <div className={'Slider-Component contenedor ' + (!active ? "Slider-Component-OFF" : "Slider-Component-ON")}>
        {!active &&<span >Start Charging </span>}
        <div id="miCirculo"
            >
            <div className='Slider-Icon'>
                {active && <Logo />}

                {!active &&<ArrowBlue className='Slider-Icon-rotate'  />}
            </div>
        </div>



        {active && (<a href="/#" className="line-animation" >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </a>) }

        {active &&<span >Charging Started</span>}



    </div>
  </>
  )
}

export default Slider
