import React from 'react'

const Facebook = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_9864_2493)">
    <path d="M20 10C20 4.5 15.5 0 10 0C4.5 0 0 4.5 0 10C0 15 3.7 19.1 8.4 19.9V12.9H5.9V10H8.4V7.8C8.4 5.3 9.9 3.9 12.2 3.9C13.3 3.9 14.4 4.1 14.4 4.1V6.6H13.1C11.9 6.6 11.5 7.4 11.5 8.2V10H14.3L13.9 12.9H11.6V19.9C16.3 19.1 20 15 20 10Z" fill="#2F80ED"/>
    <path d="M13.9 12.8999L14.3 9.9999H11.5V8.0999C11.5 7.2999 11.9 6.4999 13.1 6.4999H14.4V4.0999C14.4 4.0999 13.3 3.8999 12.2 3.8999C9.90002 3.8999 8.40002 5.2999 8.40002 7.7999V9.9999H5.90002V12.8999H8.40002V19.8999C9.40002 20.0999 10.5 20.0999 11.5 19.8999V12.8999H13.9Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_9864_2493">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default Facebook