//import React, { useContext } from 'react'

//styles
import './TitleText.css'
//import { PromisePageContext } from 'src/machines/pageMachine'

interface Props{
    text: string
    styles?: React.CSSProperties
}

const TitleText = ({text, styles}:Props) => {

  /*
  const { page } = useContext(PromisePageContext);

   
  const styles2 = {
    backgroundColor: page.context.darkMode ? "#141D2F" : "#FFFFFF",
    color: page.context.darkMode ? "#FFFFFF" : "#0000",
  }
  
  */
  return (
    <span className='TitleText' style={styles}>{text}</span>
  )
}

export default TitleText
