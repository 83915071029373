import React , { useState }from 'react'
import { useParams } from 'react-router-dom'

//styles
import './SplashScreen.css'
import PrincipalButton from '../../components/PrincipalButton/PrincipalButton'

const Splashscreen = () => {

  const { chargerId } = useParams();
  const [chargerIdState] = useState(chargerId);
  
  return (
    <div className='SplashScreen-page'>
      <img className='Logo' src='/image/logo.png' alt='Logo site'></img>
      <span>EV Charging in Condos made Simple</span>
      <img className='SplashScreen-image' src='/image/SplashscreenAnimate.gif' alt='Splashscreen'></img>
      <p>
        By signing up, you agree with our friendly 
        <a href="https://jplug.io" target="_blank" rel="noopener noreferrer">
          <b> terms and conditions</b>
        </a>.
      </p>



      <PrincipalButton redirect='/PaymentMethod' text='Get Started' version='white' chargerId={chargerIdState} ></PrincipalButton>
    </div>
  )
}

export default Splashscreen
