import React from 'react';

import TitleText from 'src/components/TitleText/TitleText';
import HeaderBack from 'src/components/HeaderBack/HeaderBack';
import InputText from 'src/components/InputText/InputText';
import PrincipalButton from 'src/components/PrincipalButton/PrincipalButton';

import './SettingsPaymentMethod.css';


const SettingsPaymentMethod = () => {
    return (
        <div className='SettinsPaymentMethod-layout'>
            <HeaderBack title='Payment Method'></HeaderBack>
            <TitleText text='Your Payment Method' styles={{marginBottom:"28px"}}></TitleText>
            <InputText type='text' label='Name on Card' placeholder='Jiaro Martins' styles={{marginBottom:"15px"}} />
            <InputText type='text' label='Card Number' placeholder='xxx - xxxx - xxx - xxx' styles={{marginBottom:"15px"}} />
            <div className='splitDiv'>
                <div style={{width:"40%"}}>
                    <InputText type='text' label='Expiration' placeholder='MM / AA' styles={{marginBottom:"31px"}} />
                </div>

                <div style={{width:"40%"}}>
                    <InputText type='text' label='CVV' placeholder='...' styles={{marginBottom:"31px"}} />
                </div>
            </div>
            <PrincipalButton text='Update'></PrincipalButton>
        </div>
    );
};


export default SettingsPaymentMethod;