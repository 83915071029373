import React from 'react'

//styles
import './SliderBasic.css'

interface Props {
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
}

const SliderBasic = ({active, setActive}:Props) => {
  return (
    <div className={'SliderBasic-Component ' + (!active ? "Slider-Component-OFF" : "Slider-Component-ON")} onClick={()=>{setActive(!active)}}>
        <div style={{left:(!active ? "calc(5%)" : "calc(95% - 45px)")}}>
        </div>

    </div>
  )
}

export default SliderBasic